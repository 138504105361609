.timeInput {
  border: 1px solid #E5E5E5 !important;
  border-radius: 8px;
  font-size: 1rem;
}

.timeInput:invalid {
  color: #C6C6C6;
}

.timeInput:focus {
  color: #000;
}

.pickerRoot {
  height: 100%;
  padding: 0.75rem 1rem;
}

.pickerRoot::before {
  content: none !important;
}

.pickerRoot::after {
  content: none !important;
}

.pickerInput::placeholder {
  color: #C6C6C6 !important;
  opacity: 1 !important;
}
