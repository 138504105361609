.card {
  background: #FFFFFF;

  box-shadow: 0rem 1rem 1rem rgba(64, 48, 141, 0.16);
  border-radius: 1rem;
}

.viewEventsHeader {
  padding-bottom: 0.75rem;
}

.eventDivider:first-of-type {
  border-top: 1px solid #EFEFEF;
  margin-top: 0px;
}

.selectedEventDivider:first-of-type {
  border-top: 1px solid #EFEFEF;
  margin-top: 0px;
  margin-bottom: 0px;
  padding-top: 1rem;
}

@media (min-width: 1200px) {
  .cardContainer {
    padding-left: 2rem;
  }
}

.submitButton {
  flex-shrink: 0;
  width: 10.5rem;
  height: 3rem;
  font-size: 1.125rem;
}

.deleteButton {
  width: 6.5rem;
  height: 3rem;
  font-size: 1.125rem;
  margin-right: 1rem;
  border-width: 1px;
}
