.login {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.login > * {
  align-self: flex-start;
}

.form {
  width: 100%;
}

.title {
  font-size: 2.25rem;
  font-weight: 900;
  margin-bottom: 2rem;
}

.input {
  border: 1px solid #E5E5E5;
  border-radius: 20px;
  padding: 0.75rem 1rem;
  font-size: 1rem;
}

.input::placeholder {
  font-size: 1rem;
  color: #C6C6C6;
}

.input:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.forgotPassword, .forgotPassword:hover, .forgotPassword:focus {
  color: #795DE7;
}

.submitButton {
  flex-shrink: 0;
  font-size: 1.125rem;
}

.loginErrorContainer {
  position: relative;
  width: 100%;
}

.loginError {
  right: 0px;
  position: absolute;
}
