@font-face {
  font-family: 'Nunito';
  src: url('assets/fonts/Nunito-Regular.ttf');
}

:root {
  --background-color: #F3F1FD;
}

html {
  font-size: 16px;
}

body {
  background-color: var(--background-color);
  margin: 0;
  font-family: 'Nunito', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a.unstyle-link {
  color: unset;
  text-decoration: none;
}

a.unstyle-link:hover, a.unstyle-link:focus {
  color: unset;
  text-decoration: none;
}