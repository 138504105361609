.dialog {
  display: flex;
  flex-direction: column;
  padding: 2rem;
  background-color: #F3F1FD;
  width: 60%;
  max-width: 60% !important;

  box-shadow: 0px 24px 32px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04) !important;
  border-radius: 16px !important;
}

.body {
  display: flex;
  align-items: center;
  justify-content: center;
}

.closeButton {
  border: none !important;
  outline: none !important;
  background: none;
}

.title {
  font-weight: 900;
  font-size: 36px;
  line-height: 48px;

  display: flex;
  align-items: center;

  color: #000000;

  flex: none;
  order: 0;
  flex-grow: 1;
  margin: 0px 0px;
}