.button {
  border: 1.5px solid #795DE7;
  border-radius: 1.25rem;
  background-color: #795DE7;
  color: #FFF;

  font-weight: 800;
  font-size: 14px;

  padding-right: 1.5rem;
  padding-left: 1.5rem;
}

.button:hover:enabled {
  border: 1.5px solid #795DE7;
  background-color: #FFF;
  color: #795DE7;
}

.button:hover:disabled {
  cursor: not-allowed;
  color: #FFF;
}
