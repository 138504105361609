.searchBar {
  background-color: rgba(49, 28, 105, 0.2);
  border-color: rgba(49, 28, 105, 0.2);
}

.iconButton {
  font-size: 1.25rem;
}

.input:focus {
  box-shadow: none;
}
