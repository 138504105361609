.description {
  resize: none;
}

.input {
  border: 1px solid #E5E5E5;
  border-radius: 8px;
  padding: 0.75rem 1rem;
  font-size: 1rem;
}

.input:invalid {
  box-shadow: none !important;
}

.input::placeholder {
  font-size: 1rem;
  color: #C6C6C6;
}

.tag {
  padding-left: 2rem !important;
}

.tagBox {
  width: 140px;
  height: 200px;
  position: relative;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  border: 1px solid #EFEFEF;
  backdrop-filter: blur(16px);
  border-radius: 8px;
}

.tagOverlay {
  position: absolute;
  background-color: transparent;
  top: 0px;
  bottom: 0px;
  width: 100%;

  padding: 0px !important;
  border: none !important;
  outline: none !important;

  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.add {
  flex-grow: 1;
}

.hidden {
  visibility: hidden;
}

.addButton {
  font-weight: 800;
  font-size: 16px;
  line-height: 24px;
  text-align: center;

  padding: 12px;
  width: 100%;

  color: #795DE7;
  background: #F3F1FD;
  backdrop-filter: blur(16px);
}

.scheduleGroup {
  display: flex;
  align-items: center;
  vertical-align: middle;
  text-align: center;
  justify-content: flex-start;
}

.checkboxRoot {
  color: #795DE7 !important;
}

.inputHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.inputTip {
  color: #9D9D9D;
  font-size: 1rem;
}

.externalLinkDemo {
  align-self: flex-end;
  margin-top: 0.25rem;
}