.card {
  background: #FFFFFF;

  box-shadow: 0rem 1rem 1rem rgba(64, 48, 141, 0.16);
  border-radius: 1rem;
}

.viewEventsHeader {
  padding-bottom: 0.75rem;
}

.eventDivider {
  border-top: 1px solid #EFEFEF;
}

@media (min-width: 1200px) {
  .cardContainer {
    padding-left: 2rem;
  }
}

.submitButton {
  flex-shrink: 0;
  width: 8.5rem;
  height: 3rem;
  font-size: 1.125rem;
}
