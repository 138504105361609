.title {
  font-size: 2.25rem;
  font-weight: 900;
}

.loading {
  position: fixed;
  top: 50vh;
  left: calc(50vw - 40px);
}
